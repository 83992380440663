import React from 'react'
import { DateTime } from 'luxon'
import { DI } from 'modules'

class GridTimeComponent extends React.Component {
  static defaultProps = {
    view: 'gc',
  }

  render() {
    const { time, view } = this.props
    const currentDate = DateTime.local()

    let format = this.props.common.strings['page.video.date.format']

    const offset = DateTime.fromISO(time).offset
    const isInDST = DateTime.fromISO(time).isInDST // is summer time
    const fixedZone = isInDST ? offset - 60 : offset

    const videoDate = DateTime.fromISO(time).setZone(fixedZone)

    if (currentDate.year === videoDate.year) {
      format = format.replace(', YYYY', '')
    }

    return (
      <>
        {this.props.time && (
          <div className={`${view}-time ${view}-time--not-live`}>
            <span className={`${view}__time`}>
              {videoDate.toFormat(format)}
            </span>
          </div>
        )}
      </>
    )
  }
}

export const GridTime = DI(['user'])(GridTimeComponent)
