import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import Dropdown from 'components/dropdown'

export class CountryFilter extends Component {
  static preload(props) {
    props.actions.geodata.getCountriesForFilter()
  }

  // componentDidMount() {
  //     CountryFilter.preload(this.props);
  // }

  // componentDidUpdate(prevProps) {
  //     const localeIsUpdated =
  //         this.props.common.locale !== prevProps.common.locale;

  //     if (localeIsUpdated) {
  //         CountryFilter.preload(this.props);
  //     }
  // }

  onClickReset = ev => {
    ev.preventDefault()

    const params = {
      ...this.props.common.searchParams,
    }

    delete params.q.country_id_in
    delete params.page

    this.props.actions.common.updateSearchParams(
      params,
      this.props.common.isMobile
    )
  }

  render() {
    const { strings } = this.props.common
    const { name, value } = this.props

    return (
      <Fragment>
        <Dropdown
          items={this.props.geodata.countriesForFilter.payload}
          getTitle={item => item.name}
          getValue={item => item.id}
          headerModificator="filter"
          placeholder={strings['vods.filters.countries.placeholder']}
          name={name}
          value={value}
          isInput={true}
        />
        <div className="vod-filter-block-reset">
          <a
            href="#"
            className={this.classList(
              'text-button-reset',
              !value && '_disabled'
            )}
            onClick={this.onClickReset}
          >
            {strings['vods.filters.date.reset']}
          </a>
        </div>
      </Fragment>
    )
  }
}

export default DI(['geodata'])(CountryFilter)
