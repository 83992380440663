import React from 'react'
import { withRouter } from 'react-router-dom'
import { DI } from 'modules'
import SearchForm from 'components/search-form'
import Component from 'components/component'
import AEFilter from 'components/ae-filter'
import { plural } from 'helpers/strings'

export class ToolsPanel extends Component {
  state = {
    filter: false,
    settings: false,
    focus: false,
  }

  toggleStateValue = name => ev => {
    this.setState(state => ({
      [name]: !state[name],
    }))
  }

  onChangeFocus = focus => {
    this.setState({ focus })
  }

  onOpenMobileFilters = () => {
    this.props.actions.common.toggleMobileFilter()
    document.body.style.overflow = 'hidden'
  }

  render() {
    const { strings, searchParams } = this.props.common
    const count = this.props.videos.count
    const localesResults = this.props.videos.localesResults

    const className = this.classList(
      'tools-panel',
      this.state.settings && '_vis',
      this.state.focus && '_focused'
    )
    const filterClassName = this.classList(
      'tools-btn tools-btn--filter',
      this.props.common.mobileFilterOpen && '_active'
    )
    const settingsClassName = this.classList(
      'tools-btn tools-btn--settings',
      this.state.settings && '_active'
    )

    const hasTags = searchParams.q && searchParams.q.tags_id_in
    const hasSearch = searchParams.search

    return (
      <div className={className}>
        <div className="tools-panel__head">
          <SearchForm
            onChangeFocus={this.onChangeFocus}
            classNamePrefix="tools-search"
          />
          <button
            className={filterClassName}
            onClick={this.onOpenMobileFilters}
          />
          <button
            className={settingsClassName}
            onClick={this.toggleStateValue('settings')}
          />
        </div>
        <div className="tools-panel__body">
          <AEFilter showRelevant />
        </div>
        {(hasTags || hasSearch) && (
          <div className="tools-panel__footer">
            <div className="tools-found">
              <div className="tools-found__all">
                {strings['vods.found']}
                {count} {plural(count, strings['vods.found.videos'])}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default DI(['videos', 'locales'])(withRouter(ToolsPanel))
