import React from 'react'
import Loadable from 'react-loadable'

const Tippy = Loadable({
  loading: ()=><></>,
  loader: () => import(/* webpackChunkName: "tippy" */ '@tippy.js/react')
})

export default class Tooltip extends React.Component {
  static defaultProps = {
    animation: 'fade',
    arrow: false,
    placement: 'bottom-start',
  }

  render() {
    return <Tippy {...this.props} />
  }
}
