import React from 'react'
import { DI } from 'modules'
import { withRouter } from 'react-router'
import Component from 'components/component'
import ToolsPanel from './tools-panel'
import List, { LiveItemAdapter, VideoItemAdapter } from 'components/list'
import Pagination from 'components/pagination'
import { PER_PAGE } from '../config'
import {
  AESidebarFilterDesktop,
  AESidebarFilterMobile,
} from 'components/ae-sidebar-filter'
import PageLoading from 'components/page-loading'
import { ActionCableConsumer } from 'components/action-cable-wrapper'
import ContentNotFound from 'components/content-not-found'

import * as routes from 'config/routes'

export class LandingContent extends Component {
  paginateVideos = []

  state = {
    loading: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { common, load } = this.props
    const localeIsUpdated = common.locale !== prevProps.common.locale
    const paramsIsUpdated =
      JSON.stringify(common.searchParams) !==
      JSON.stringify(prevProps.common.searchParams)

    if (paramsIsUpdated || localeIsUpdated) {
      if (!common.searchParams.hasOwnProperty('pages')) {
        this.paginateVideos = []
      }

      this.setState({
        loading: true,
      })

      load().finally(() => {
        this.setState({
          loading: false,
        })
      })
    }
  }

  get entity() {
    const {
      common: { searchParams },
      tags,
    } = this.props

    return tags.byPage.getValue(searchParams.page || 1)
  }

  handlerLoadMore = () => {
    this.paginateVideos = [
      ...this.paginateVideos,
      ...this.entity.payload.videos,
    ]
  }

  onReceivedWebsocket = msg => {
    this.props.actions.tags.wsStatusChangeLandingLiveList(msg.data)
  }

  render() {
    const {
      common: { searchParams, strings },
      tags: { listViewTypeLanding, filteredEntityTag },
      location: { search },
    } = this.props
    const videos = this.paginateVideos.concat(this.entity.payload.videos)
    const videos_count = this.entity.payload.videos_count
    const lives_count = this.entity.payload.lives_count
    const lives = this.entity.payload.lives

    const queryParams = new URLSearchParams(search)
    const searchedText = queryParams.get('search') || searchParams.search
    const defaultUrl = routes.landingDashboard.getPath()
    const isLoading = this.state.loading || filteredEntityTag.pending || this.entity.pending;

    return (
      <div className="content" style={{ zIndex: 0 }}>
        <ActionCableConsumer
          channel="Content::LiveEventsChannel"
          onReceived={this.onReceivedWebsocket}
        />
        <div className="iwrap">
          <ToolsPanel livesCount={lives_count} videosCount={videos_count} />

          <div className="ae-list-with-filter">
            { isLoading ? (<PageLoading />) : (
              lives_count === 0 && videos_count === 0 ? (
                <ContentNotFound
                  defaultUrl={defaultUrl}
                  searchedText={searchedText}
                />
              ) : (
                <div className="ae-list-with-filter__helper">
                  <div className="laes">
                    {lives.length > 0 && (
                      <div className="lae">
                        <span className="lae__title">
                          {strings['header.menu.live']}
                        </span>
                        <List listViewType={listViewTypeLanding} withFilter>
                          {lives.map(live => (
                            <LiveItemAdapter
                              key={live.id}
                              imageSize="large"
                              live={live}
                            />
                          ))}
                        </List>
                      </div>
                    )}
                    {videos.length > 0 ? (
                      <div className="lae">
                        <span className="lae__title">
                          {strings['page.landing.linear_view.title']}
                        </span>
                        <List listViewType={listViewTypeLanding} withFilter>
                          {videos.map(video => (
                            <VideoItemAdapter
                              key={video.id}
                              imageSize="large"
                              slideshowEnabled={true}
                              video={video}
                            />
                          ))}
                        </List>
                      </div>
                    ) : (
                      <ContentNotFound
                        defaultUrl={defaultUrl}
                        searchedText={searchedText}
                      />
                    )}
                  </div>
                  <Pagination
                    count={videos_count}
                    page={searchParams.page}
                    perPage={PER_PAGE}
                    loadMore={this.handlerLoadMore}
                  />
                </div>
              )
            )}
            <div className="ae-sidebar-filter">
              <AESidebarFilterDesktop viewType="landing" />
              <AESidebarFilterMobile viewType="landing" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DI(['common', 'tags'])(withRouter(LandingContent))
