import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'
import FollowTag from 'pages/content/components/follow-tag'

class LandingHead extends Component {
  state = {
    tag: {},
  }

  componentWillUpdate(nextProps) {
    const nextImg = nextProps.tag.image.original.url
    const currImg = this.props.tag.image.original.url

    if (
      (currImg !== nextImg && nextImg) ||
      (nextImg && !this.props.common.landingPage)
    ) {
      this.setState({
        tag: nextProps.tag
      })

      this.props.actions.common.landingPage(true)
    }
  }

  render() {
    const { tag } = this.state
    const img = tag?.image?.original?.url

    if(tag === null) {
      return null
    }

    return (
      <section
        className={Component.classList(
          'tag-landing-header',
          !img && 'tag-landing-header--no-cover'
        )}
        style={{ marginBottom: 0 }}
      >
        {img && (
          <div className="tag-landing-header__cover">
            <img src={img} alt={`#${tag.slug}`} />
          </div>
        )}
        <div className="tag-landing-header__inner">
          <div className="tag-landing-header__title">{tag.name}</div>
          <div
            className="tag-landing-header__text"
            dangerouslySetInnerHTML={{ __html: tag.description }}
            style={{ width: '100%' }}
          />
        </div>
      </section>
    )
  }
}

export default DI(['common'])(LandingHead)