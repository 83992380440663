import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import { DI } from 'modules'
import Component from 'components/component'
import Checkbox from 'components/form/checkbox'
import FollowTag from 'pages/content/components/follow-tag'
import { IS_LANDING } from 'config'

export class TagsFilter extends Component {
  static defaultProps = {
    value: [],
    onChange() {},
  }

  state = {
    showAll: false,
  }

  isMenuTag =
    this.props.menu &&
    this.props.menu.mainMenu.some(({ children }) => {
      if (children instanceof Array) {
        const res = children.some(
          ({ localeLink }) => localeLink === this.props.location.pathname
        )
        return res
      }
      return false
    })

  showAll = () => {
    this.setState({
      showAll: true,
    })
  }

  showLess = () => {
    this.setState({
      showAll: false,
    })
  }

  getTagsList = () => {
    return this.props.tags.list.payload
      .filter(e => e.state === 'regular')
      .reduce(
        (result, item) => {
          if (this.props.value.includes(item.id)) {
            result.checked.push(item)
          } else {
            result.unchecked.push(item)
          }
          return result
        },
        {
          checked: [],
          unchecked: [],
        }
      )
  }

  onClickReset = ev => {
    if (ev) {
      ev.preventDefault()
    }

    const params = {
      ...this.props.common.searchParams,
    }

    delete params.q.tags_id_in
    delete params.page
    // this.props.history.push(`/${this.props.common.locale}/videos`);
    this.props.actions.common.updateSearchParams(
      params,
      this.props.common.isMobile
    )
  }

  renderTag = checked => item => {
    let rest = {}

    if (checked) {
      rest.onChange = this.onClickReset
    }

    return (
      <Checkbox
        key={item.id}
        className="text-checkbox"
        name={this.props.name}
        value={item.id}
        checked={checked}
        {...rest}
      >
        {item.name}
      </Checkbox>
    )
  }

  render() {
    const { strings } = this.props.common
    const { checked, unchecked } = this.getTagsList()

    return (
      <Fragment>
        <div className="vod-filter-tags">
          <div className="vod-filter-tags-selected">
            {!checked.length && (
              <span className="vod-filter-tags__none">
                {strings['vods.filters.tags.noselected']}
              </span>
            )}
            {checked.map(this.renderTag(true))}
            {!IS_LANDING && checked.length === 1 && (
              <div className="choosen-tag-single">
                <FollowTag
                  tag={checked[0]}
                  className="btn-bord--follow"
                  style={{ margin: 0 }}
                />
                <span className="choosen-tag-single__text">
                  {strings['vods.filters.tags.follow_tip']}
                </span>
              </div>
            )}
          </div>
          <div className="vod-filter-tags-list">
            <span className="vod-filter-tags__title">
              {strings['vods.filters.tags.available']}
            </span>
            <div className="vod-filter-tags__list">

              {(this.state.showAll ? unchecked : unchecked.slice(0, 5)).map(
                this.renderTag(false)
              )}
              {/*<div className="text-checkbox">
                                <input type="checkbox" className="text-checkbox__input" id="tag07" />
                                <label htmlFor="tag07" className="text-checkbox__label" data-tippy-content="Too long title of the greatest tag ever">Too long title of the greatest tag ever</label>
                            </div>*/}
              <span className="vod-filter-tags__none">
                {strings['vods.filters.tags.all_selected']}
              </span>
            </div>
          </div>
          <div
            className={this.classList(
              'vod-filter-tags-more',
              this.state.showAll && '_more'
            )}
          >
            <span className="vod-filter-tags-more__link">
              <span
                className="vod-filter-tags-more__more"
                onClick={this.showAll}
              >
                {strings['vods.filters.tags.show_all']}
              </span>
              <span
                className="vod-filter-tags-more__less"
                onClick={this.showLess}
              >
                {strings['vods.filters.tags.show_less']}
              </span>
            </span>
          </div>
          <div className="vod-filter-block-reset">
            <a
              href="#"
              className={this.classList(
                'text-button-reset',
                !checked.length && '_disabled'
              )}
              onClick={this.onClickReset}
            >
              {strings['vods.filters.date.reset']}
            </a>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(DI(['tags', 'menu'])(TagsFilter))
