import React from 'react'
import Component from 'components/component'

import FilterForm from 'components/ae-sidebar-filter/filter-form'

export default class AESidebarFilterDesktop extends Component {
  render() {
    const { viewType } = this.props

    return viewType === 'landing' ? (
      <>
        <div className="ae-sidebar-filter">
          <div className="vod-filter">
            <FilterForm {...this.props} allowShowList={{ showTags: [] }} />
          </div>
        </div>
        <div className="ae-sidebar-filter">
          <div className="vod-filter">
            <FilterForm
              {...this.props}
              allowShowList={{
                showDate: [],
                showCategories: [],
                showTags: [],
                showRegions: [],
                showCountries: [],
                showFormat: [],
                showType: [],
                showDuration: [],
                showTags: ['landing'],
              }}
            />
          </div>
        </div>
      </>
    ) : (
      <div className="ae-sidebar-filter">
        <div className="vod-filter">
          <FilterForm {...this.props} />
        </div>
      </div>
    )
  }
}
