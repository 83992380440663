import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import page from 'hoc/page'
import Component from 'components/component'
import { DI } from 'modules'
import frontload from 'hoc/frontload'
import LandingHead from './landing-head'
import LandingContent from './landing-content'
import { PER_PAGE } from './config'
import HelmetWrap from 'components/helmet-wrap'

import 'submodules/responsivestyles/src/less/tag-landing.less'

export class Landing extends Component {
  static preload(props) {
    const makePreload = async () => {
      const getInfo = () => props.tags.byPage.getValue(1)
      let params = { ...props.common.searchParams }
      const isNotInit = !getInfo().isInited
      delete params.pages

      const queryParams = new URLSearchParams(props.location.search);
      const search = queryParams.get('search');
      
      if (search && search !== '') {
        params = {
          ...params,
          search
        }
      }

      const hasSearchParams = (() => {
        const updParams = { ...params }
        delete updParams.page
        return Boolean(Object.keys(updParams).length)
      })()

      let info = getInfo().payload

      if (isNotInit || !hasSearchParams) {
        info = await props.actions.tags.getEntityTag({
          slug: props.slug || props.match.params.id,
          per_page: PER_PAGE,
          ...params,
          page: params.page || 1,
        })
        info = info.body
      }

      if (hasSearchParams) {
        const searchTags =
          params.q && params.q.tags_id_in ? params.q.tags_id_in : []
        const videosResp = await props.actions.tags.getFilteredEntityTag({
          slug: props.slug || props.match.params.id,
          page: 1,
          per_page: PER_PAGE,
          ...params,
          q: {
            ...(params.q || {}),
            tags_id_in: [...searchTags],
          },
        })
        return videosResp
      }

      return null
    }

    return makePreload().catch(error => {
      return Promise.reject(error)
    })
  }

  load = async () => {
    return Landing.preload(this.props)
  }

  componentDidMount() {
    this.props.actions.geodata.getCountriesForFilter()
  }

  render() {
    return (
      <Fragment>
        <HelmetWrap {...(this.meta_tag || {})} title={this.tag.name} />
        <LandingHead tag={this.tag} />
        <LandingContent load={this.load} />
      </Fragment>
    )
  }

  get page() {
    return this.props.common.searchParams.page || 1
  }

  get tag() {
    return this.props.tags.byPage.getValue(this.page).payload.tag
  }

  get meta_tag() {
    return this.props.tags.byPage.getValue(this.page).payload.meta_tag
  }

  get tagSlug() {
    return this.props.match.params.id
  }
}

export default page(DI(['tags', 'geodata'])(frontload(withRouter(Landing), 'landing')))
