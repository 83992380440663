import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import Radio, { Radios } from 'components/form/radio'

export class TypeFilter extends Component {
  onChangeAny = ev => {
    ev.stopPropagation()

    const params = {
      ...this.props.common.searchParams,
      page: 1,
    }

    delete params.q.limit_type_eq_filter
    delete params.page

    this.props.actions.common.updateSearchParams(
      params,
      this.props.common.isMobile
    )
  }

  render() {
    const { strings } = this.props.common
    const { name, value } = this.props

    const types = [
      {
        title: strings['vods.filters.type.paid'],
        value: 'regular_content',
      },
      {
        title: strings['vods.filters.type.pool'],
        value: 'pool',
      },
      {
        title: strings['vods.filters.type.free'],
        value: 'free',
      },
    ]

    return (
      <Fragment>
        <Radios>
          <Radio
            name={name}
            value=""
            checked={value === undefined}
            onChange={this.onChangeAny}
          >
            {strings['vods.filters.any']}
          </Radio>
        </Radios>
        <Radios>
          {types.map(type => (
            <Radio
              key={type.value}
              name={name}
              value={type.value}
              checked={String(value) === String(type.value)}
            >
              {type.title}
            </Radio>
          ))}
        </Radios>
      </Fragment>
    )
  }
}

export default DI()(TypeFilter)
