import React from 'react';
import './range-slider.css';
import RangeSlider from './slider';
import RangeTime from './time';

class MainRange extends React.Component {
  constructor(props) {
    super(props);

    this.timer;
    this.state = {
      values: [this.props.min, this.props.max]
    };
  }

  callBackFunc = (values = this.state.values) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const [_from, _to] = values;
      this.fromInput.value = _from;
      this.toInput.value = _to;
      const event = new Event('input', { bubbles: true });

      this.fromInput.dispatchEvent(event);
      this.toInput.dispatchEvent(event);
    }, 500);
  };

  setValuesHandler = (values, accessReq = true) => {
    this.setState({ values }, () => {
      if (accessReq) this.callBackFunc(values);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const { minFromParam, min, maxFromParam, max, isLoaded } = this.props;
    if (prevProps.isLoaded !== isLoaded) {
      this.setState({
        values: [minFromParam || min, maxFromParam || max]
      });
    }
    const checkProps =
      (!minFromParam && prevProps.minFromParam) ||
      (!maxFromParam && prevProps.maxFromParam);

    if (checkProps && isLoaded) {
      this.setState({
        values: [min, max]
      });
    }
  }

  render() {
    const childProps = {
      min: this.props.min,
      max: this.props.max,
      onChanged: this.setValuesHandler,
      values: this.state.values
    };

    return (
      <div className="rangeSlider">
        <input
          type="hidden"
          name={this.props.fromName}
          ref={ref => (this.fromInput = ref)}
        />
        <input
          type="hidden"
          name={this.props.toName}
          ref={ref => (this.toInput = ref)}
        />

        <RangeSlider {...childProps} stepValue={1000} />

        {this.props.withFields && <RangeTime {...childProps} />}
      </div>
    );
  }
}

MainRange.defaultProps = {
  min: 0,
  max: 0,
  withFields: false,
  isLoaded: false,
  fromName: '',
  toName: ''
};

export default MainRange;
