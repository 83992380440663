import React from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import Component from 'components/component'

import { videosList } from 'config/routes'

export class AENotFound extends Component {
  render() {
    const { strings } = this.props.common
    return (
      <div className="ae-not-found">
        <div className="ae-not-found__text">
          {strings['vods.not_found.text']}
        </div>

        <div className="ae-not-found__or">
          {strings['vods.not_found.text_2']}
        </div>
        <div className="ae-not-found__btn">
          <Link className="btn-bord" to={videosList.getPath()}>
            {strings['vods.not_found.link']}
          </Link>
        </div>
      </div>
    )
  }
}

export default DI()(AENotFound)
