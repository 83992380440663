import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { DI } from 'modules'
import Component from 'components/component'
import { isServer } from 'store'
import Hammer from 'react-hammerjs'
import OuterClick from 'components/outer-click'

import FilterForm from 'components/ae-sidebar-filter/filter-form'

var options = {
  touchAction: 'compute',
  recognizers: {
    tap: {
      time: 600,
      threshold: 100,
    },
  },
}

class AESidebarFilterDesktop extends Component {
  componentDidMount() {
    this.node = document.querySelector('#modal_filter_wrapper')
  }

  onCloseMobileFilters = () => {
    this.props.actions.common.toggleMobileFilter()
    document.body.style.overflow = 'unset'
  }

  render() {
    if (isServer || !this.node) {
      return null
    }
    const { viewType } = this.props

    const vis = this.props.common.mobileFilterOpen

    const overlayClassName = this.classList('filter-overlay', vis && '_vis')

    const filterClassName = this.classList('mobile-filter', vis && '_vis')

    return ReactDOM.createPortal(
      <Fragment>
        <div className={overlayClassName} />
        <Hammer
          onSwipeRight={this.onCloseMobileFilters}
          direction="DIRECTION_HORIZONTAL"
          options={options}
        >
          <div className={filterClassName}>
            <OuterClick on={vis ? this.onCloseMobileFilters : () => {}}>
              <div className="mobile-filter-inner">
                {viewType === 'landing' ? (
                  <>
                    <div className="ae-sidebar-filter mobile">
                      <div className="vod-filter">
                        <FilterForm
                          {...this.props}
                          allowShowList={{ showTags: [] }}
                          mobile
                        />
                      </div>
                    </div>
                    <div className="ae-sidebar-filter mobile">
                      <div className="vod-filter">
                        <FilterForm
                          {...this.props}
                          allowShowList={{
                            showDate: [],
                            showCategories: [],
                            showTags: [],
                            showRegions: [],
                            showCountries: [],
                            showFormat: [],
                            showType: [],
                            showDuration: [],
                            showTags: ['landing'],
                          }}
                          mobile
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="ae-sidebar-filter mobile">
                    <div className="vod-filter">
                      <FilterForm {...this.props} mobile />
                    </div>
                  </div>
                )}
              </div>
            </OuterClick>
          </div>
        </Hammer>
      </Fragment>,
      this.node
    )
  }
}

export default DI()(AESidebarFilterDesktop)
