import React from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import Component from 'components/component'
import 'submodules/responsivestyles/src/less/content-not-found.less'

export class ContentNotFound extends Component {
  render() {
    const {
      defaultUrl,
      searchedText,
      common: { strings },
    } = this.props

    return (
      <div className="content-not-found">
        <div className="content-not-found__title">
          {strings['content.not_found.title']}{' '}{searchedText && <>&laquo;{searchedText}&raquo;</>}
        </div>
        <div className="content-not-found__text">
          {strings['content.not_found.text']}
        </div>
        <div className="content-not-found__btn">
          <Link className="btn-bord" to={defaultUrl}>
            {strings['content.not_found.link']}
          </Link>
        </div>
      </div>
    )
  }
}

export default DI()(ContentNotFound)
