import React from 'react'
import { DI } from 'modules'
import MainRange from 'common/components/range-slider'

class DurationFilter extends React.Component {
  render() {
    const {
      isInited,
      pending,
      payload: { max_duration, min_duration },
    } = this.props.videos.rangeDuration
    const { searchParams } = this.props.common
    const { duration_lteq, duration_gteq } = searchParams.q || {}

    return (
      <MainRange
        withFields
        min={min_duration}
        max={max_duration}
        minFromParam={duration_gteq}
        maxFromParam={duration_lteq}
        isLoaded={isInited && !pending}
        fromName="q[duration_gteq]"
        toName="q[duration_lteq]"
      />
    )
  }
}

export default DI(['videos', 'common'])(DurationFilter)
