import { VOD_HOST } from 'config'
import { DI } from 'modules'
import React from 'react'
import { Helmet } from 'react-helmet'

export function HelmetWrapper(props) {
  const {
    live,
    video,
    page,
    common: { strings },
  } = props
  const pathname = props.router.location.pathname

  const defaultText = isDescription => {
    const siteTitle = strings['site.title']
    switch (page) {
      case 'about':
        return `About | ${siteTitle}`
      case 'broadcast':
        return `Broadcasts | ${siteTitle}`
      case 'career':
        return `Careers | ${siteTitle}`
      case 'pricing':
        return `Pricing | ${siteTitle}`
      case 'cookies':
        return `Cookies | ${siteTitle}`
      case 'impressum':
        return `Impressum | ${siteTitle}`
      case 'planning':
        return `Planning section | ${siteTitle}`
      case 'privacy':
        return `Privacy Policy | ${siteTitle}`
      case 'team':
        return `Team | ${siteTitle}`
      case 'terms':
        return `Terms of Service | ${siteTitle}`
      case 'dashboard':
        return `Dashboard | ${siteTitle}`
      case 'videos':
        return `Videos | Video ${siteTitle}`
      case 'video':
        return `${
          isDescription ? video.description : video.title
        } | Video ${siteTitle}`
      case 'events':
        return `${
          isDescription ? live.description : live.title
        } | Live ${siteTitle}`
      default:
        return `${siteTitle}`
    }
  }

  const title = props['title'] || defaultText()
  const description = props['description'] || defaultText(true)
  const links = [
    {
      rel: 'canonical',
      href: `${VOD_HOST}${props.canonical ? props.canonical : pathname}`,
    },
  ]

  if (props.amp) {
    links.push({
      rel: 'amphtml',
      href: `${VOD_HOST}${props.canonical ? props.canonical : pathname}/amp`,
    })
  }

  return (
    <Helmet
      title={title}
      link={links}
      meta={[
        {
          property: 'title',
          content: title,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: props['og_title'] || title,
        },
        {
          property: 'og:description',
          content: props['og_description'] || description,
        },
        {
          property: 'og:image',
          content:
            (props.og_image && props.og_image.original.url) ||
            (live && live.image.large_brand.url) ||
            (video && video.selected_preview_image.url),
        },
        {
          property: 'og:image:width',
          content: '578',
        },
        {
          property: 'og:image:height',
          content: '325',
        },
        {
          property: 'twitter:title',
          content: props['twitter_title'] || title,
        },
        {
          property: 'twitter:description',
          content: props['twitter_description'] || description,
        },
        {
          property: 'twitter:image',
          content:
            (props.og_image && props.og_image.original.url) ||
            (live && live.image.large_brand.url) ||
            (video && video.selected_preview_image.url),
        },
        {
          property: 'twitter:image:width',
          content: '578',
        },
        {
          property: 'twitter:image:height',
          content: '325',
        },
      ]}
    />
  )
}

export default DI(['common', 'router'])(HelmetWrapper)
