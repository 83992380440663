import React, { useCallback, useMemo } from 'react'
import { getTrackBackground, Range } from 'react-range'
import './range-slider.css'

function RangeSlider(props) {
  const trackBackground = useMemo(
    () =>
      getTrackBackground({
        values: props.values,
        colors: ['#f2f2f2', '#77bc1f', '#f2f2f2'],
        min: props.min,
        max: props.max,
      }),
    [props.values, props.min, props.max]
  )

  const moveSliderHandler = useCallback(val => {
    props.onChanged(val, false)
  }, [])

  const moveEndSliderHandler = useCallback(val => {
    props.onChanged(val)
  }, [])

  return (
    <Range
      values={props.values}
      step={props.stepValue}
      min={props.min}
      max={props.max}
      onChange={moveSliderHandler}
      onFinalChange={moveEndSliderHandler}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className="rangeSliderBack"
        >
          <div
            ref={props.ref}
            className="rangeSliderFront"
            style={{
              background: trackBackground,
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div {...props} className="rangeSliderThumb" />
      )}
    />
  )
}

RangeSlider.defaultProps = {
  min: 0,
  max: 0,
  onChanged: values => {},
  values: [0, 0],
}

export default RangeSlider
