import React from 'react'
// import DEPRECATED_moment from 'moment-timezone';
import { DI } from 'modules'
import Component from 'components/component'
import Dropdown from 'components/dropdown'
import { DateTime } from 'luxon'

export class TimezoneSelect extends Component {
  onSelectZone = (ev, selected) => {
    this.props.actions.user.selectTimezone(selected)
  }

  compareCities(a, b) {
    let cityA = a.code.split('/', 2)[1].toLocaleLowerCase()
    let cityB = b.code.split('/', 2)[1].toLocaleLowerCase()
    return (
      (b.offset < a.offset) - (a.offset < b.offset) ||
      (cityB < cityA) - (cityA < cityB)
    )
  }

  render() {
    const sortedArray = this.props.locales.timezones.payload.sort(
      this.compareCities
    )

    const browserTimeZone =
      Intl && Intl.DateTimeFormat
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : 'Etc/UTC'

    const defTimezone =
      this.props.defTimezone && !this.props.user.isSignedIn
        ? browserTimeZone
        : this.props.user.timezone

    const params =
      this.props.name && this.props.value
        ? { name: this.props.name, value: this.props.value }
        : {
            onChange: this.onSelectZone,
            value: defTimezone,
          }

    return (
      <Dropdown
        items={sortedArray}
        getSelectedTitle={item => {
          let zone = DateTime.local().setZone(item.code).toFormat('ZZ')
          return `GMT ${zone}`
        }}
        getTitle={item => {
          let city = (item.code || '').replace(/^\w+\//, '').replace('_', ' ')
          let zone = DateTime.local().setZone(item.code).toFormat('ZZ')
          return `(GMT ${zone}) ${city}`
        }}
        getValue={item => item.code}
        headerModificator="small"
        wrapperAttrs={{ dir: 'ltr' }}
        {...params}
      />
    )
  }
}

export default DI(['locales', 'user'])(TimezoneSelect)
