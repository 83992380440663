import React from 'react'
import Component from 'components/component'
import defaultNoimg from 'images/large_noimg.png'

const loaded = []

export default class Image extends Component {
  state = {
    url: this.props.url,
  }

  static defaultProps = {
    loadingIndicator: true,
    render: (state, props) => {
      const { loadingIndicator, ...otherProps } = props
      return (
        <span
          style={{ backgroundImage: `url(${state.url})` }}
          {...otherProps}
        />
      )
    },
    noimg: defaultNoimg,
  }

  preloadImage = url => {
    return new Promise((resolve, reject) => {
      const image = document.createElement('img')
      image.onload = resolve
      image.onerror = reject
      image.src = url
    })
  }

  setImage = () => {
    if (!loaded.includes(this.url)) {
      if (this.url) {
        this.setState({
          loading: true,
        })
        this.preloadImage(this.url)
          .then(() => {
            loaded.push(this.url)
            this.state.loading &&
              this.setState({
                stub: false,
                loading: false,
              })
          })
          .catch(
            () =>
              this.state.loading &&
              this.setState({
                url: this.props.noimg,
                stub: true,
                loading: false,
              })
          )
      } else {
        this.setState({
          url: this.props.noimg,
          stub: true,
          loading: false,
        })
      }
    } else {
      this.setState({
        stub: false,
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.setImage()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.url) {
      this.setImage()
    }
  }

  render() {
    const { render, url, noimg, ...props } = this.props

    return render(
      {
        url: this.state.stub ? noimg : url,
        loading: this.props.loadingIndicator && this.state.loading,
      },
      props
    )
  }

  get url() {
    return this.props.url
  }
}
