import React from 'react'
import { DI } from 'modules'
import { withRouter } from 'react-router'
// import DEPRECATED_moment from 'moment';
const availableLocales = ['en', 'ru', 'es', 'ar']

export default function page(Component, defaultProps = {}) {
  class Page extends React.Component {
    static defaultProps = {
      clear: false,
      landing: false,
      ...defaultProps,
    }

    constructor(props) {
      super(props)
      this.props.actions.common.clearPage(this.props.clear)
      this.props.actions.common.landingPage(this.props.landing)
      // moment.locale(this.props.match.params.locale)
    }

    componentDidMount() {
      window.copied_text = this.props.common.strings.copied_text
      window.isAdmin = this.props.user.currentUser.payload.user_role === 'admin'

      this.setLocale()

      if (this.props.pageName) {
        this.props.actions.common.addPageClass(`page--${this.props.pageName}`)
      }
    }

    componentDidUpdate(prevProps) {
      window.copied_text = this.props.common.strings.copied_text
      window.isAdmin = this.props.user.currentUser.payload.user_role === 'admin'
      if (this.props.match.params.locale !== prevProps.match.params.locale) {
        this.setLocale()
      }
    }

    componentWillUnmount() {
      if (this.props.pageName) {
        this.props.actions.common.removePageClass(
          `page--${this.props.pageName}`
        )
      }
    }

    setLocale() {
      if (availableLocales.includes(this.props.match.params.locale)) {
        this.props.actions.common.setLocale(this.props.match.params.locale)
      }
    }

    render() {
      const { actions, common, ...props } = this.props
      
      return <Component {...props} />
    }
  }

  return DI(['user'])(withRouter(Page))
}
