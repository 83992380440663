import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga'
import { DI } from 'modules'
import HelmetWrap from 'components/helmet-wrap'
import Component from 'components/component'
import frontload from 'hoc/frontload'
import page from 'hoc/page'
import PageLoading from 'components/page-loading'
import PageSubmenu from 'components/page-submenu'
import ToolsPanel from './tools-panel'
import Pagination from 'components/pagination'
import List, { VideoItemAdapter } from 'components/list'
import {
  AESidebarFilterDesktop,
  AESidebarFilterMobile,
} from 'components/ae-sidebar-filter'
import AESNotFound from './ae-not-found'
import * as routes from 'config/routes'
import { VOD_HOST, COMMON_DOMAIN } from 'config'
import MylistModal from 'components/my-lists-modal'
import qp from 'query-params-helpers'

import 'submodules/responsivestyles/src/less/all-events.less'

export class LatestVideos extends Component {
  static async preload(props) {
    const params = await LatestVideos.convertParams(props)
    props.actions.common.setSearchParams(params)

    const videosList = props.actions.videos.getList({
      page: props.common.pagination.page,
      per_page: props.common.isMobile ? 10 : 60,
      ...params,
    })

    const frontPage = props.actions.frontpages.getFrontPage('videos')

    return Promise.all([videosList, frontPage])
  }

  static convertParams = async props => {
    if (props.match.path === routes.videosFilter.path) {
      const { name } = props.match.params
      const id = (name.match(/^(\d+)-/) || [])[1]
      const getFilterParam = (name, value) => ({
        q: {
          [name]: value,
        },
      })
      const byTagID = id => getFilterParam('tags_id_in', [id])
      const byCategoryID = id => getFilterParam('categories_id_in', [id])
      const byCountryID = id => getFilterParam('country_id_in', id)
      const byRegionID = id => getFilterParam('region_id_in', [id])
      const byTagSlug = async slug => {
        let list = props.tags.list.payload

        if (!(list || []).length) {
          const req = await props.actions.tags.getTags()
          list = req.body
        }
        const tag = (list || []).find(tag => tag.slug === slug)

        if (tag) {
          switch (tag.state) {
            case 'regular':
              return byTagID(tag.id)
            case 'special':
              return byCategoryID(tag.id)
          }
        } else {
          props.actions.videos.clearList()
        }
      }

      switch (props.match.params.filter) {
        case 'tag':
          return byTagID(+id)
        case 'category':
          return byCategoryID(+id)
        case 'country':
          return byCountryID(+id)
        case 'region':
          return byRegionID(+id)
        default:
          return await byTagSlug(name)
      }
    } else {
      // const params = {...props.common.searchParams}

      // props.actions.videos.getList({
      //   page: 1,
      //   per_page: props.isMobile ? 10 : 60,
      //   ...params,
      // });
      return props.common.searchParams
    }
  }

  componentDidMount() {
    const { actions, videos } = this.props
    !videos.rangeDuration.isInited && actions.videos.getDuration()
  }

  componentDidUpdate(prevProps) {
    const {
      common: { searchParams, locale, isMobile },
      actions,
      location,
      history,
      match,
    } = this.props
    const curr = searchParams
    const prev = prevProps.common.searchParams

    const localeIsUpdated = locale !== prevProps.common.locale

    if (location.pathname !== prevProps.location.pathname) {
      LatestVideos.convertParams(this.props).then(params => {
        if (match.path === routes.videosList.path) {
          const hasParams =
            params.q &&
            Object.keys(params).length === 1 &&
            Object.keys(params.q).length === 1
          if (hasParams && (params.q.tags_id_in || []).length === 1) {
            delete params.q.tags_id_in

            actions.videos.getList({
              page: 1,
              per_page: isMobile ? 10 : 60,
              ...params,
            })
          } else if (
            hasParams &&
            (params.q.categories_id_in || []).length === 1
          ) {
            delete params.q.categories_id_in

            actions.videos.getList({
              page: 1,
              per_page: isMobile ? 10 : 60,
              ...params,
            })
          }

          if (params.search) {
            actions.videos.getList({
              page: 1,
              per_page: isMobile ? 10 : 60,
              ...params,
            })
          }
        }
        actions.common.setSearchParams(params)
      })
    } else if (localeIsUpdated || curr !== prev) {
      // if (history.location.search && curr.page === prev.page) {
      //   history.push(
      //     routes.videosList.getPath(locale) + history.location.search
      //   )
      // }
      actions.videos.getList({
        page: 1,
        per_page: isMobile ? 10 : 60,
        ...searchParams,
      })
    }
  }

  getMetaTags = () => {
    if (this.props.match && this.props.match.params.filter) {
      const name = this.props.match.params.name
      const id = (name.match(/^(\d+)-/) || [])[1]
      const tag = this.props.tags.list.payload.find(
        tag => tag.id === Number(id)
      )
      return (tag && tag.meta_tag) || {}
    } else return this.props.frontpages.info.payload.meta_tag
  }

  meta_tag = () => {
    const { q = {} } = qp.parse(this.props.history.location.search) || {}
    const { tags_id_in = [], categories_id_in = [] } = q
    let tagIDs = [...tags_id_in, ...categories_id_in]

    const tag =
      tagIDs.length === 1
        ? this.props.tags.list.payload.find(tag => tag.id === Number(tagIDs[0]))
        : null

    return tagIDs.length === 1 ? tag && tag.meta_tag : this.getMetaTags()
  }

  state = {
    meta_tag: this.meta_tag(),
  }

  // ----- add to list -----
  setCurrentVideo = externalName => {
    this.setState({ externalName })
  }

  openMyListModal = () => {
    this.props.actions.common.openNewListModal()
  }

  closeMyListModal = title => {
    if (title && title.length) {
      ReactGA.event({
        category: this.props.location.pathname,
        action: 'Add to list',
        label: title,
      })
    }
    this.props.actions.common.closeNewListModal()
  }

  confirmMyListModal = title => {
    const externalName = this.state.externalName
    const video = {
      item_type: 'video_project',
      item_id: externalName,
    }
    const user_id = this.props.user.currentUser.payload.id

    this.props.actions.user.createList(user_id, title, [], [video]).then(
      () => {
        this.closeMyListModal(title)
      },
      this.props.actions.notifications.createNotification({
        type: 'success',
        children: this.props.common.strings['page.profile.menu.after_update'],
      })
    )
  }

  // ----- add to list -----

  get pageTitle() {
    const {
      common: {
        searchParams: { q },
        strings,
      },
      tags,
      geodata,
    } = this.props
    const def = strings['page.video.title']
    const getValue = key => {
      if (q && q[key] instanceof Array)
        return q[key].length === 1 ? q[key][0] : undefined
      return q && q[key]
    }
    const ids = {
      tag: getValue('tags_id_in'),
      category: getValue('categories_id_in'),
      region: getValue('region_id_in'),
      country: getValue('country_id_in'),
    }

    if (Object.values(ids).some(id => id !== undefined)) {
      const check = key => ids[key] !== undefined
      const getTitle = val =>
        val ? `${def} ${strings['page.video.title_about']} «${val.name}»` : def
      const selectTag = check('tag')
      const selectCategory = check('category')
      const selectRegion = check('region')
      const selectCountry = check('country')

      const selectTags = selectTag || selectCategory
      const isMultiply =
        (selectTag && selectCategory) ||
        (selectTags && selectRegion) ||
        (selectTags && selectCountry)
      if (isMultiply) {
        return def
      } else if (selectTag || selectCategory) {
        const tag = (tags?.list?.payload || []).find(
          ({ id }) => id === +ids.tag || id === +ids.category
        )
        return getTitle(tag)
      } else if (selectRegion) {
        const region = (geodata?.regionsForFilter?.payload || []).find(
          ({ id }) => id === +ids.region
        )
        return getTitle(region)
      } else if (selectCountry) {
        const country = (geodata?.countriesForFilter?.payload || []).find(
          ({ id }) => id === +ids.country
        )
        return getTitle(country)
      }
    }
    return def
  }

  get displayVideos() {
    const { common, videos } = this.props

    return Array.prototype.concat.apply(
      [],
      common.pagination.pages.map(page => videos.byPage.getValue(page).payload)
    )
  }

  render() {
    const {
      common: { strings },
    } = this.props
    const pathString = this.props.router.location
    const { q = {} } = this.props.common.searchParams
    const { page } = this.props.common.pagination
    const { tags_id_in = [], categories_id_in = [] } = q

    const locale = this.props.common.locale

    const submenu = this.props.menu.mainMenu.length
      ? this.props.menu.mainMenu[0].children
      : []

    let tagIDs = [...tags_id_in, ...categories_id_in]

    const tagTitle = this.props.tags.list.payload
      .filter(item => tagIDs.includes(item.id))
      .map(item => item.name)
      .join(' | ')

    const tag =
      tagIDs.length === 1
        ? this.props.tags.list.payload.find(tag => tag.id === tagIDs[0])
        : null

    const meta_tag =
      (tagIDs.length === 1 ? tag && tag.meta_tag : this.getMetaTags()) ||
      this.state.meta_tag

    const dynamicTitle = () => {
      if (tagTitle) {
        switch (locale) {
          case 'en':
            return `${tagTitle} - Latest Video News | ${strings['site.title']}`
          case 'ru':
            return `${tagTitle} - Последние новости и видео | ${strings['site.title']}`
          case 'es':
            return `${tagTitle} - Ultimas Noticias y videos | ${strings['site.title']}`
          case 'ar':
            return `${tagTitle} - آخر الأخبار عن  | ${strings['site.title']}`
        }
      } else return strings['site.title']
    }
    const dynamicDescription = () => {
      if (tagTitle) {
        switch (locale) {
          case 'en':
            return `Find all the latest video news and articles about ${tagTitle} on ${COMMON_DOMAIN}`
          case 'ru':
            return `Последние новости и видеоматериалы о ${tagTitle} на ${COMMON_DOMAIN}`
          case 'es':
            return `Sigue la actualidad noticias y vídeos de ${tagTitle} en ${COMMON_DOMAIN}`
          case 'ar':
            return `اعثر على أحدث أخبار ومقالات الفيديو حول ${tagTitle} على ${COMMON_DOMAIN}`
        }
      } else return strings['site.title']
    }

    const pending = this.props.videos.byPage.getValue(
      this.props.common.pagination.page
    ).pending

    return (
      <section className="content">
        <div className="iwrap">
          {tagTitle && tagIDs.length ? (
            <Helmet
              title={dynamicTitle()}
              link={[
                {
                  rel: 'canonical',
                  href: `${VOD_HOST}${pathString.pathname}${pathString.search}`,
                },
              ]}
              meta={[
                {
                  property: 'title',
                  content: dynamicTitle(),
                },
                {
                  property: 'description',
                  content: dynamicDescription(),
                },
                {
                  property: 'og:title',
                  content: dynamicTitle(),
                },
                {
                  property: 'og:description',
                  content: dynamicDescription(),
                },
                {
                  property: 'twitter:title',
                  content: dynamicTitle(),
                },
                {
                  property: 'twitter:description',
                  content: dynamicDescription(),
                },
              ]}
            />
          ) : (
            <HelmetWrap {...(meta_tag || {})} page="videos" />
          )}

          <PageSubmenu items={submenu} withoutParams />
          <ToolsPanel />
          <div className="page-title-video-list">
            <span className="page-title-video-list__left">
              <span className="page-title-video-list__text">
                {this.pageTitle}
              </span>
            </span>
            <div className="page-title-video-list__spinner">
              {pending && <PageLoading showSpinner />}
            </div>
          </div>
          <div className="ae-list-with-filter">
            <div className="ae-list-with-filter__helper">
              {this.displayVideos.length > 0 ? (
                <Fragment>
                  <List withFilter>
                    {this.displayVideos.map(video => (
                      <VideoItemAdapter
                        key={video.id}
                        video={video}
                        setCurrentVideo={this.setCurrentVideo}
                      />
                    ))}
                  </List>
                  {!pending && (
                    <Pagination
                      page={page}
                      perPage={this.props.common.isMobile ? 10 : 60}
                      count={this.props.videos.count}
                    />
                  )}
                </Fragment>
              ) : (
                <AESNotFound />
              )}
            </div>
            <AESidebarFilterDesktop />
            <AESidebarFilterMobile />
          </div>

          {this.props.common.newListModal && (
            <MylistModal
              close={this.closeMyListModal}
              confirm={this.confirmMyListModal}
              title={this.props.common.strings['page.my_lists.add_list_new']}
              placeholder={
                this.props.common.strings['page.my_lists.list.modal.list_name']
              }
            />
          )}
        </div>
      </section>
    )
  }
}

export default page(
  DI([
    'videos',
    'tags',
    'menu',
    'geodata',
    'frontpages',
    'router',
    'common',
    'user',
    'notifications',
  ])(frontload(LatestVideos, 'latestVideos'))
)
