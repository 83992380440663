import React from 'react'
import Component from 'components/component'

export default class GCMark extends Component {
  static defaultProps = {
    open: false,
    classNamePrefix: 'gc',
    titleList: [],
    textList: [],
  }

  state = {
    init: false,
  }

  componentDidMount() {
    if (this.full) {
      this.fullWidth = this.full.offsetWidth
    }
    this.setState({
      init: true,
    })
  }

  render() {
    const { isLocked, open, textList, classNamePrefix, titleList } = this.props
    if (!isLocked && !titleList.some(Boolean)) return null

    return (
      <div className={`${classNamePrefix}__mark`}>
        {isLocked && <span className="content__lock" />}
        {isLocked && Boolean(titleList.length) && <span>&nbsp;</span>}
        {titleList.map(
          (title, index) =>
            title && (
              <div key={title} className="gc__mark-item">
                {title}
                {textList[index] && (
                  <span
                    className={`${classNamePrefix}__mark-item-full`}
                    ref={el => (this.full = el)}
                    style={
                      this.state.init
                        ? {
                            width: open ? this.fullWidth : 0,
                          }
                        : void 0
                    }
                  >
                    &nbsp;
                    {textList[index]}
                  </span>
                )}
              </div>
            )
        )}
      </div>
    )
  }
}
