import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import Checkbox, { Checkboxes } from 'components/form/checkbox'

export class CategoriesFilter extends Component {
  static defaultProps = {
    value: [],
  }

  get value() {
    return this.props.value.map(Number)
  }

  state = {
    showAll: false,
  }

  renderCategory = (item, index) => {
    return (
      <Checkbox
        key={item.id}
        name={this.props.name}
        value={item.id}
        checked={this.value.includes(item.id)}
      >
        {item.name}
      </Checkbox>
    )
  }

  onChangeAny = ev => {
    const { common, actions } = this.props
    ev.stopPropagation()
    // debugger

    const params = {
      ...common.searchParams,
      page: 1,
    }

    delete params.q.categories_id_in
    delete params.page

    actions.common.updateSearchParams(params, common.isMobile)
  }

  render() {
    const { strings } = this.props.common
    const categories = this.props.tags.list.payload.filter(
      e => e.state === 'special'
    )

    return (
      <Fragment>
        <Checkboxes>
          <Checkbox
            key={'any'}
            checked={!this.value.length}
            disabled={!this.value.length}
            onChange={this.onChangeAny}
          >
            {strings['vods.filters.any']}
          </Checkbox>
        </Checkboxes>
        <Checkboxes>{categories.map(this.renderCategory)}</Checkboxes>
      </Fragment>
    )
  }
}

export default DI(['tags'])(CategoriesFilter)
