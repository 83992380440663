import React from 'react'
import { DI } from 'modules'
import { withRouter } from 'react-router'
import Form from 'common/components/form/new-form'
import Component from 'components/component'

import DateFilter from 'pages/components/filter/date'
import TagsFilter from 'components/ae-sidebar-filter/tags-filter'
import CategoriesFilter from 'components/ae-sidebar-filter/categories-filter'
import RegionFilter from 'components/ae-sidebar-filter/region-filter'
import CountryFilter from 'components/ae-sidebar-filter/country-filter'
import FormatFilter from 'components/ae-sidebar-filter/format-filter'
import TypeFilter from 'components/ae-sidebar-filter/type-filter'
import DurationFilter from 'components/ae-sidebar-filter/duration-filter'
import FilterBlock from 'pages/components/filter/block'

export class AESidebarFilter extends Component {
  static defaultProps = {
    viewType: 'videos',
    allowShowList: null,
  }

  onClickReset = ev => {
    ev.preventDefault()
    const { common, history, actions, viewType } = this.props
    this.onChange(ev, {})
    const params = {
      ...common.searchParams,
    }

    delete params.q
    delete params.page
    viewType === 'videos' && history.push(`/${common.locale}/videos`)
    actions.common.updateSearchParams(params, common.isMobile)
  }

  onClickHideFilters = ev => {
    const { actions } = this.props
    ev.preventDefault()
    actions.common.toggleMobileFilter()
    document.body.style.overflow = 'unset'
  }

  onChange = (ev, form) => {
    const { common, actions } = this.props
    const params = {
      ...common.searchParams,
      ...form,
    }

    if (!form.q) {
      delete params.q
    }
    delete params.page

    if (ev.target.value !== '') {
      if (form.q) {
        if (
          form.q.translations_published_at_gteq &&
          !/T.*/.test(form.q.translations_published_at_gteq)
        ) {
          params.q.translations_published_at_gteq += 'T00:00:00'
        }
        if (
          form.q.translations_published_at_lteq &&
          !/T.*/.test(form.q.translations_published_at_lteq)
        ) {
          params.q.translations_published_at_lteq += 'T23:59:59'
        }
      }

      actions.common.updateSearchParams(params, common.isMobile)
    }
  }

  get showConfig() {
    const { viewType } = this.props

    const allowShowList = {
      showDate: ['videos', 'landing'],
      showCategories: ['videos'],
      showTags: ['videos'],
      showRegions: ['videos'],
      showCountries: ['videos', 'landing'],
      showFormat: [],
      showType: ['videos'],
      showDuration: ['videos'],
      ...this.props.allowShowList,
    }
    const showConfig = {}

    // eslint-disable-next-line guard-for-in
    for (const key in allowShowList) {
      showConfig[key] = allowShowList[key].includes(viewType)
    }

    return showConfig
  }

  render() {
    const {
      common: { searchParams, strings },
      mobile,
    } = this.props
    const { q = {} } = searchParams
    const {
      showDate,
      showCategories,
      showTags,
      showRegions,
      showCountries,
      showFormat,
      showType,
      showDuration,
    } = this.showConfig

    return (
      <Form onChange={this.onChange} onlyWithValue>
        <div>
          {mobile && (
            <div className="vod-filter-block vod-filter-block--not-spoiler vod-filter-block--hide">
              <a
                className="hide-filter-button hide-filter-button--hide"
                href="#"
                onClick={this.onClickHideFilters}
              >
                {strings['vods.filters.hide_filters']}{' '}
                <span className="hide-filter-button__arrow" />
                <span className="hide-filter-button__arrow" />
              </a>
            </div>
          )}
          {showDate && (
            <FilterBlock openDefault title={strings['vods.filters.date.title']}>
              <DateFilter
                end={{
                  name: 'q[translations_published_at_lteq]',
                  value: q.translations_published_at_lteq,
                }}
                start={{
                  name: 'q[translations_published_at_gteq]',
                  value: q.translations_published_at_gteq,
                }}
              />
            </FilterBlock>
          )}

          {showCategories && (
            <FilterBlock
              openDefault
              title={strings['vods.filters.categories.title']}
            >
              <CategoriesFilter
                name="q[categories_id_in][]"
                value={q.categories_id_in}
              />
            </FilterBlock>
          )}
          {showTags && (
            <FilterBlock openDefault title={strings['vods.filters.tags.title']}>
              <TagsFilter name="q[tags_id_in][]" value={q.tags_id_in} />
            </FilterBlock>
          )}
          {showRegions && (
            <FilterBlock
              openDefault
              title={strings['vods.filters.regions.title']}
            >
              <RegionFilter name="q[region_id_in][]" value={q.region_id_in} />
            </FilterBlock>
          )}

          {showCountries && (
            <FilterBlock
              openDefault
              title={strings['vods.filters.countries.title']}
            >
              <CountryFilter name="q[country_id_in]" value={q.country_id_in} />
            </FilterBlock>
          )}
          {showFormat && (
            <FilterBlock
              openDefault
              title={strings['vods.filters.format.title']}
            >
              <FormatFilter name="q[format_eq]" value={q.format_eq} />
            </FilterBlock>
          )}
          {showType && (
            <FilterBlock openDefault title={strings['vods.filters.type.title']}>
              <TypeFilter
                name="q[limit_type_eq_filter]"
                value={q.limit_type_eq_filter}
              />
            </FilterBlock>
          )}

          {showDuration && (
            <FilterBlock
              openDefault
              title={strings['vods.filters.duration.title']}
            >
              <DurationFilter />
            </FilterBlock>
          )}

          <div className="vod-filter-block vod-filter-block--not-spoiler">
            <a
              className={this.classList(
                'text-button-reset',
                !searchParams.q && '_disabled'
              )}
              href="#"
              onClick={this.onClickReset}
            >
              {strings['vods.filters.reset']}
            </a>
          </div>
        </div>
      </Form>
    )
  }
}

export default withRouter(DI()(AESidebarFilter))
