import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import Checkbox, { Checkboxes } from 'components/form/checkbox'

export class RegionFilter extends Component {
  static preload(props) {
    return props.actions.geodata.getRegionsForFilter()
  }

  static defaultProps = {
    value: [],
  }

  get value() {
    return this.props.value.map(Number)
  }

  state = {
    showAll: false,
  }

  // componentDidMount() {
  //     RegionFilter.preload(this.props);
  // }

  // componentDidUpdate(prevProps) {
  //     const localeIsUpdated =
  //         this.props.common.locale !== prevProps.common.locale;

  //     if (localeIsUpdated) {
  //         RegionFilter.preload(this.props);
  //     }
  // }

  showAll = () => {
    this.setState({
      showAll: true,
    })
  }

  showLess = () => {
    this.setState({
      showAll: false,
    })
  }

  renderRegion = (item, index) => {
    return (
      <Checkbox
        key={item.id}
        name={this.props.name}
        value={item.id}
        checked={this.value.includes(item.id)}
      >
        {item.name}
      </Checkbox>
    )
  }

  onChangeAny = ev => {
    ev.stopPropagation()
    // debugger

    const params = {
      ...this.props.common.searchParams,
      page: 1,
    }

    delete params.q.region_id_in
    delete params.page

    this.props.actions.common.updateSearchParams(
      params,
      this.props.common.isMobile
    )
  }

  render() {
    const { strings } = this.props.common
    const regions = this.props.geodata.regionsForFilter.payload
    const first = regions.slice(0, 3)
    const second = this.state.showAll
      ? []
      : regions.slice(3).filter(item => this.value.includes(item.id))
    const third = this.state.showAll
      ? regions.slice(3)
      : regions.slice(3).filter(item => !this.value.includes(item.id))

    return (
      <Fragment>
        <Checkboxes>
          <Checkbox
            key={'any'}
            checked={!this.value.length}
            disabled={!this.value.length}
            onChange={this.onChangeAny}
          >
            {strings['vods.filters.any']}
          </Checkbox>
        </Checkboxes>
        <Checkboxes>
          {first.map(this.renderRegion)}
          {second.map(this.renderRegion)}
        </Checkboxes>
        <div
          className={this.classList(
            'vod-filter-spoiler',
            this.state.showAll && '_opened'
          )}
        >
          <div className="vod-filter-spoiler__body">
            <Checkboxes>{third.map(this.renderRegion)}</Checkboxes>
          </div>
          <span className="vod-filter-spoiler__head">
            <span className="vod-filter-spoiler__link">
              <span
                className="vod-filter-spoiler__closed"
                onClick={this.showAll}
              >
                {strings['vods.filters.regions.show_all']}
              </span>
              <span
                className="vod-filter-spoiler__opened"
                onClick={this.showLess}
              >
                {strings['vods.filters.regions.show_less']}
              </span>
            </span>
          </span>
        </div>
      </Fragment>
    )
  }
}

export default DI(['geodata'])(RegionFilter)
