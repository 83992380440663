import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'
import { plural } from 'helpers/strings'
import SearchForm from 'components/search-form'
import * as routes from 'config/routes'
import { useParams } from 'react-router-dom'

function ToolsPanel({ videosCount, livesCount, tags, actions, common }) {
  const params = useParams()
  const [toolsPanelShown, setToolsPanelShown] = React.useState(false)
  const [focus, setFocus] = React.useState(false)

  const selectViewType = type => ev => {
    ev.preventDefault()
    actions.common.setListViewType(type)
    actions.tags.setListViewTypeLanding(type)
  }

  const onOpenMobileFilters = () => {
    actions.common.toggleMobileFilter()
    document.body.style.overflow = 'hidden'
  }

  const onChangeFocus = focus => {
    setFocus({ focus })
  }

  return (
    <div
      className={Component.classList(
        'tools-panel',
        toolsPanelShown && '_vis',
        focus && '_focused'
      )}
    >
      <div className="tools-panel__head">
        <SearchForm
          onChangeFocus={onChangeFocus}
          classNamePrefix="tools-search"
          searchRoute={routes.landingDashboard}
          searchRouteParams={params}
          suggestsEnabled={false}
          isLanding
        />
        <button
          className="tools-btn tools-btn--settings"
          onClick={() => setToolsPanelShown(!toolsPanelShown)}
          type="button"
        />
        <button
          className={Component.classList(
            'tools-btn tools-btn--filter',
            common.mobileFilterOpen && ' _active'
          )}
          onClick={onOpenMobileFilters}
          type="button"
        />
      </div>
      <div className="tools-panel__body">
        <div className="ae-filter ae-filter--tl">
          <div className="ae-filter-view ae-filter-view--tl">
            <div className="ae-views">
              {common.listViewTypes.map(type => (
                <div
                  key={type}
                  className={Component.classList(
                    `ae-view`,
                    type === tags.listViewTypeLanding && '_active'
                  )}
                >
                  <a
                    className={Component.classList(
                      `ae__view ae__view--${type}`,
                      type === tags.listViewTypeLanding && '_active'
                    )}
                    href="#"
                    onClick={selectViewType(type)}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="ae-filter-relevant" />

          <div className="ae-filter-count">
            <span className="ae-filter-count__count">
              {videosCount} {common.strings['vods.found.videos']} ·{' '}
              {plural(
                livesCount,
                common.strings['page.lives.list.count_of_lives']
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DI(['common', 'tags'])(ToolsPanel)
