import React from 'react'
import 'video.js/dist/video-js.min.css'
import './style.css'
import { withRouter } from 'react-router-dom'
import { livesList, livesView, myEventsList, videoView, landingDashboard } from 'config/routes'

const watermarkStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundImage: `url(${require('./watermark.png')})`,
  backgroundPosition: '0 center',
  backgroundRepeat: 'no-repeat',
  top: 0,
  left: 0,
}

class VideoPlayer extends React.Component {
  static defaultProps = {
    style: {},
    onPlayerReady() {},
    watermark: true,
    format: '',
  }

  componentDidMount() {
    const { onPlayerReady, format, match, ...options } = this.props

    if (
      [
        livesList.path,
        myEventsList.path,
        livesView.path,
        videoView.path,
        landingDashboard.path,
      ].includes(match.path)
    ) {
      import(/* webpackChunkName: "video.js" */ 'video.js').then(module => {
        try {
          this.player = module.default(this.videoNode, options, onPlayerReady)

          this.player.on('waiting', function () {
            this.addClass('vjs-waiting')
          })

          this.player.on('playing', function () {
            this.removeClass('vjs-waiting')
          })
        } catch (err) {}

        if (format === 'spherical') {
          import(/* webpackChunkName: "videojs-vr" */ 'videojs-vr').then(
            moduleVR => {
              this.player.registerPlugin('vr', moduleVR.default)
              this.player.vr({ projection: '360' })
            }
          )
        }
      })
    }
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ overflow: 'hidden', ...this.props.style }}
      >
        <div data-vjs-player style={{ position: 'absolute', top: 0 }}>
          <video
            ref={node => (this.videoNode = node)}
            crossOrigin="anonymous"
            className="video-js vjs-default-skin vjs-16-9"
            preload="auto"
          />
          {this.props.watermark && <div style={watermarkStyle} />}
        </div>
      </div>
    )
  }
}

export default withRouter(VideoPlayer)
